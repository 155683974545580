import React from 'react';
import './App.css';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from "./screens/Home";
import DailyBread from "./screens/DailyBread";
import ShareMusic from "./screens/ShareMusic";
import BibleTest from "./screens/BibleTest";
import Book from "./screens/Book";
import Pray from "./screens/Pray";
import Caring from "./screens/Caring";
import Mission from "./screens/Mission";
import About from "./screens/About";
import Ebook from "./screens/ebook";
import FlipPage from "./screens/FlipPage";
import FlipPage1 from "./screens/FlipPage1";
import FlipPage2 from "./screens/FlipPage2";
import FlipPage3 from "./screens/FlipPage3";
import FlipPage4 from "./screens/FlipPage4";

import Error from "./screens/Error";

function App() {
  return (
    <BrowserRouter>
        <Switch>
          <Route path="/" component={Home} exact/>
          <Route path="/manna/:id" component={DailyBread} />
          <Route path="/sharemusic" component={ShareMusic} />
          <Route path="/bibletest" component={BibleTest} />
          <Route path="/book" component={Book} />
          <Route path="/pray" component={Pray} />
          <Route path="/caring" component={Caring} />
          <Route path="/mission" component={Mission} />
          <Route path="/about" component={About} />
          <Route path="/ebook" component={FlipPage} />
          <Route path="/ash" component={FlipPage1} />
          <Route path="/generalmeeting2022" component={FlipPage2} />
          <Route path="/goodfriday2022" component={FlipPage3} />
          <Route path="/pray0512" component={FlipPage4} />
          <Route component={Error} />
        </Switch>
    </BrowserRouter>
  );
}

export default App;

