import React, { useEffect } from 'react';
import withRoot from '../modules/withRoot';
import AppAppBar from '../modules/views/AppAppBar';
import DailyBread from '../modules/views/DailyBread';
import Footer from '../modules/views/Footer';
import TopBanner from '../modules/views/TopBanner';
import { firebaseAnalytics } from "../firebase/base";
import Snowfall from 'react-snowfall'

function Index(props) {


  useEffect(() => {
    //console.log(id)
    firebaseAnalytics.logEvent("dailybreadpage_visited");
  }, [props]);



  return (
    <div>
    <React.Fragment>
      <AppAppBar page="dailybread"/>
      <TopBanner />
      <DailyBread {...props}/>
      <Footer />
    </React.Fragment>
    </div>
  );
}
  
export default withRoot(Index);