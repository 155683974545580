import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  awardpgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  media_category: {
    height: 140,
  },
  media_category_bottom: {
    height: 200,
  },
});





class Index extends React.Component {
  

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container className={classes.container}>
        
          <Grid container spacing={3}>
            
            
            <Grid item xs={12} md={4} xs={12}>
              <Card>
                <CardActionArea href="/sharemusic">
                  <CardMedia
                    className={classes.media_category_bottom}
                    image="/assets/sharemusic.jpg"
                    title="Share Music"
                  />
                  <CardContent style={{ height: 130 }}>
                    <Typography gutterBottom variant="h6" component="h2">
                    诗歌分享
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    我知故我唱诗心
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    怀感恩赞美上帝
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/sharemusic">
                  阅读更多
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} xs={12}>
              <Card>
                <CardActionArea href="/bibletest">
                  <CardMedia
                    className={classes.media_category_bottom}
                    image="/assets/bibletest.jpg"
                    title="Bible Test "
                  />
                  <CardContent  style={{ height: 130 }}>
                    <Typography gutterBottom variant="h6" component="h2">
                    读经问答
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    透过问答
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    建立读经的习惯
                    </Typography>
                  
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/bibletest">
                  阅读更多
                  </Button>
                </CardActions>
              </Card>
            </Grid>

            <Grid item xs={12} md={4} xs={12}>
              <Card >
                <CardActionArea href="/book">
                  <CardMedia
                    className={classes.media_category_bottom}
                    image="/assets/book.jpg"
                    title="Mission"
                  />
                  <CardContent style={{ height: 130 }}>
                    <Typography gutterBottom variant="h6" component="h2">
                    好书介绍
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    阅读属灵书本, 
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    让你看到上帝的赐福
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/book">
                  阅读更多
                  </Button>
                </CardActions>
              </Card>
            </Grid>

          </Grid>
          
          <Grid container spacing={3} style={{ marginTop: 20 }}>

            <Grid item xs={12} md={3} xs={6}>
              <Card >
                <CardActionArea href="/pray">
                  <CardMedia
                    className={classes.media_category}
                    image="/assets/pray.jpg"
                    title="Pray"
                  />
                  <CardContent style={{ height: 150 }}>
                    <Typography gutterBottom variant="h6" component="h2">
                    彼此代祷
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    每一天“…求在上面的事…”（歌罗西书3:1）
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" color="primary" href="/pray">
                  阅读更多
                  </Button>
                </CardActions>
              </Card>
            </Grid>


            <Grid item xs={12} md={3} xs={6}>
                
                <Card >
                  
                  <CardActionArea href="/caring">
                    <CardMedia
                      className={classes.media_category}
                      image="/assets/caring.jpg"
                      title="caring"
                    />
                    <CardContent style={{ height: 150 }}>
                      <Typography gutterBottom variant="h6" component="h2">
                      关怀行动
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      生命在事奉中成长, 不必等有“能力”后才事奉（雅各书4:17）
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" href="/caring">
                    阅读更多
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12} md={3} xs={6}>
                <Card >
                  <CardActionArea href="/mission">
                    <CardMedia
                      className={classes.media_category}
                      image="/assets/mission.jpg"
                      title="Mission"
                    />
                    <CardContent style={{ height: 150 }}>
                      <Typography gutterBottom variant="h6" component="h2">
                      差传资讯
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      要叫人得生命,并且得的更丰盛（约翰福音10:10）
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" href="/mission">
                    阅读更多
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12} md={3} xs={6}>
                <Card >
                  <CardActionArea href="/about">
                    <CardMedia
                      className={classes.media_category}
                      image="/assets/aboutus.jpg"
                      title="About us"
                    />
                    <CardContent style={{ height: 150 }}>
                      <Typography gutterBottom variant="h6" component="h2">
                      关于我们
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                      哥打丁宜伯大尼基督教会成立于1956年
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button size="small" color="primary" href="/about">
                    阅读更多
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
          </Grid>

        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
