import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TodayIcon from '@material-ui/icons/Today';
import DatePicker from "react-datepicker";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import app from "../../firebase/base";
import CircularProgress from '@material-ui/core/CircularProgress';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  awardpgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  formControl: {
    margin: theme.spacing(0),
  },
  button: {
    margin: theme.spacing(1, 1, 0, 0),
  },
});





class Index extends React.Component {
  
  state ={
    startDate: new Date(),
    selectedMonth: new Date().getMonth()+1,
    selectedDay: new Date().getDate(),
    selectedYear: new Date().getFullYear(),
    currentContent: null,
    loading: true,
    helperText: '',
    error: false,
    value: '',
    correct: false
  }

  setStartDate = event => {
    
    this.state.selectedDay = event.getDate();
    this.state.selectedMonth = event.getMonth()+1;
    this.state.selectedYear = event.getFullYear();
    
    this.setState({ startDate: event , loading: true})

    const selected_date = this.state.selectedDay.toString() + this.state.selectedMonth.toString()+ this.state.selectedYear.toString();

    let ref = app.database().ref('bibletest').child(selected_date);

    ref.once('value', snapshot => {
      if(snapshot.exists()){
        this.setState({ currentContent : snapshot.val() , loading: false, helperText: ''})

      }
      else
      {
        this.setState({ currentContent : null , loading: false, helperText: ''})
      }
    })
  }

  handleSubmit = event => {
    event.preventDefault();

    this.setState({ helperText: this.state.currentContent.answer, error: false, correct: true })
  };

  componentDidMount(){

    const selected_date = this.state.selectedDay.toString() + this.state.selectedMonth.toString()+ this.state.selectedYear.toString();
    
    let ref = app.database().ref('bibletest/' +selected_date);

    ref.once('value', snapshot => {
      
      if(snapshot.exists()){
        this.setState({ currentContent : snapshot.val() , loading: false})

      }
      else
      {
        this.setState({ currentContent : null , loading: false})
      }
    })
  }

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }

    
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <MuiPickersUtilsProvider  utils={DateFnsUtils}>
        <Container className={classes.container}>
          <Grid style={{ marginBottom: 20 }}>
            <KeyboardDatePicker
            margin="normal"
            id="date-picker-dialog"
            label="日期"
            format="dd日 MM月 yyyy年"
            value={this.state.startDate}
            onChange={this.setStartDate}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
          </Grid>
          {this.state.loading ? 
        <CircularProgress color="secondary" />:
        
          this.state.currentContent ? 
          <Grid container spacing={5}>
            <Grid item xs={12} md={12}>
              <Typography marked="left" align="left" variant="h4" className={classes.maintitle} component="h2" style={{}}>
              {this.state.currentContent ? this.state.currentContent.question : ''}
                </Typography>
                <Typography variant="h6"  align="left" style={{ marginBottom: 30 }}>
                {this.state.currentContent ? this.state.currentContent.bible : ''}
                  </Typography>
                  <Grid>
                    <form onSubmit={this.handleSubmit}>
                      <FormControl component="fieldset" error={this.state.error} className={classes.formControl}>

                        
                        <FormHelperText style={{ marginTop: 30, fontSize: 24, color: this.state.error ? '#910c21' : this.state.correct ? '#5faf00': '#000000' }}>{this.state.helperText}</FormHelperText>
                      </FormControl>
                    </form>
                  </Grid>
            </Grid>
          </Grid>: 
          <Grid style={{ width: '100%'}}>
            <Typography marked="left" align="left" variant="h4" className={classes.maintitle} component="h2">
                不好意思，您选择的日期暂时没有读经问答哦...
            </Typography>
          </Grid>}
          
          
        </Container>
        </MuiPickersUtilsProvider>
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
