import React, { Component } from 'react';
import withRoot from '../modules/withRoot';
import AppAppBar from '../modules/views/AppAppBar';
import TopBanner from '../modules/views/TopBanner';
import DailyBreadHome from '../modules/views/DailyBreadHome';
import Category from '../modules/views/Category';
import Footer from '../modules/views/Footer';
import HomeMiddleText from '../modules/views/HomeMiddleText';
import Grid from '@material-ui/core/Grid'
import { firebaseAnalytics } from "../firebase/base";
import Snowfall from 'react-snowfall'

class Index extends Component {

    componentDidMount(){
      console.log(this.props)
      firebaseAnalytics.logEvent("homepage_visited");
    }
    
    render() {
      return (
        <React.Fragment>
                  <AppAppBar page="home"/>
                  <TopBanner />
                  <DailyBreadHome/>
                  <HomeMiddleText/>
                  <Category/>
                  <Footer />
        </React.Fragment>
      );
    }
}
  
export default withRoot(Index);