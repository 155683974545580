import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Button from '@material-ui/core/Button';
import { saveAs } from 'file-saver';

import throttle from 'lodash.throttle';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '25%',
    },
  },
  media_category_bottom: {
    height: 350,
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  },
  document:{
    backgroundColor: 'blue',
    width: '100%'
  }
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


class Index extends React.Component {

  constructor(props) {
    super(props)
  }

  state={
    value: 0,
    pageNumber: 1,
    numPages: null,
    PDFWidth: null
  }
  
  componentDidMount() {

    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    // setting width at initial
    this.setPDFWidth()

    // event listener when window is resized
    window.addEventListener('resize', throttle(this.setPDFWidth, 500))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', throttle(this.setPDFWidth, 500))
  }

  setPDFWidth = () => {

    const width = window.innerWidth

      this.setState({ PDFWidth: width-(width/5) })
    
    
  }

  handleChange = (event, newValue) => {
    this.setState({ value : newValue })
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages })
  }

  renderPage = () => {
    var rows = [];
    for (var i = 0; i < this.state.numPages; i++) {
      rows.push(<Page width={this.state.PDFWidth} pageNumber={i+1} />);
    }

    return rows;
  }


  onPressedDownload = (index, name) => {

    if(index == 1){
      saveAs("https://firebasestorage.googleapis.com/v0/b/bethany-e504f.appspot.com/o/pray%2Fpray1.pdf?alt=media&token=edd52a06-ba9c-4bef-82a1-a4e2acdda5d1", name);
    }
    else if(index == 2){
      saveAs("https://firebasestorage.googleapis.com/v0/b/bethany-e504f.appspot.com/o/pray%2Fpray2.pdf?alt=media&token=4a316bf1-0121-4b6d-9063-191303b2c5ce", name);
    }
    else if(index == 3){
      saveAs("https://firebasestorage.googleapis.com/v0/b/bethany-e504f.appspot.com/o/pray%2Fpray3.pdf?alt=media&token=8262bc85-e25e-4d81-b4cb-d824b9f65c58", name);
    }
    

  }

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container  className={classes.container}>
        
          <Grid container spacing={5}>
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="2021年四旬期意义" {...a11yProps(0)} />
              <Tab label="四旬期的由来" {...a11yProps(1)} />
              <Tab label="2021年复活节前40天默想" {...a11yProps(2)} />
            </Tabs>
            <TabPanel style={{ width: '100%' }} value={this.state.value} index={0}>
            <Grid>
            <Button variant="outlined" color="default" onClick={()=> this.onPressedDownload(1, "2021年四旬期意义.pdf")}>
            View PDF
              </Button>
            <Document
                
                file='assets/pray1.pdf'
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page width={this.state.PDFWidth} pageNumber={1} />
                <Page width={this.state.PDFWidth} size="A4" pageNumber={2} />
              </Document>
            </Grid>
            
              
            </TabPanel>
            <TabPanel padding={0} value={this.state.value} style={{ width: '100%'}} index={1}>
              <Grid>
              <Button variant="outlined" color="default" onClick={()=> this.onPressedDownload(2, "四旬期的由来.pdf")}>
              View PDF
              </Button>
              <Document
                
                file='assets/pray2.pdf'
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page width={this.state.PDFWidth} pageNumber={1} />
              </Document>
              </Grid>
            
            </TabPanel>
            <TabPanel padding={0} value={this.state.value} style={{ width: '100%'}} index={2}>
              <Grid>
              <Button variant="outlined" color="default" onClick={()=> this.onPressedDownload(3, "2021年复活节前40天默想.pdf")}>
                  View PDF
              </Button>

              </Grid>
            
            </TabPanel>
          </Grid>
          
          
          {/*<ReactPlayer width={"100%"} height={"100%"} className={classes.media} controls url='https://www.facebook.com/pgkmediagroup/videos/399641854260637/' />*/}
          
        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
