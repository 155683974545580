import React, { Component } from 'react';
import withRoot from '../modules/withRoot';
import AppAppBar from '../modules/views/AppAppBar';
import TopBanner from '../modules/views/TopBanner';
import Footer from '../modules/views/Footer';
import Mission from '../modules/views/Mission';
import { firebaseAnalytics } from "../firebase/base";

class Index extends Component {

  componentDidMount(){
    firebaseAnalytics.logEvent("missionpage_visited");
  }

    render() {
      return (
        <React.Fragment>
          <AppAppBar page="mission"/>
          <TopBanner />
          <Mission/>
          <Footer />
        </React.Fragment>
      );
    }
}
  
export default withRoot(Index);