import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  awardpgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    textAlign: "left",
    alignSelf: 'stretch'
  }
});





class Index extends React.Component {
  

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container className={classes.container}>
        
          <Grid container spacing={5}>
            
            
            <Grid item xs={12} md={5}>
                <Grow in={true} timeout={1000}>
                  <Typography marked="left" align="left" variant="h4" className={classes.maintitle} component="h2">
                  哥打丁宜伯大尼基督教会 BCCKT
                  </Typography>
                </Grow>
                <Grow in={true} timeout={1500} >
                  <Typography align="left" variant="caption" >
                  Bethany Christian Church Kota Tinggi
                  </Typography>
                </Grow>
                <Grow in={true} timeout={2000}>
                  <Typography variant="h6"  align="left" style={{ marginTop: 10}}>
                      {'哥打丁宜伯大尼基督教会成立于1956年'}
                  </Typography>
                  </Grow>
              <br/>
              <Grow in={true} timeout={2500}>
              <Typography variant="h5"  align="left" >
                  {'主日学 (Sunday School) - 星期六'}
              </Typography>
              </Grow>
              <Grow in={true} timeout={2500}>
              <Typography variant="h5"  align="left" >
                  {'11:00am - 12:30pm'}
              </Typography>
              </Grow>
              <br/>
              <Grow in={true} timeout={2500}>
              <Typography variant="h5"  align="left" >
                  {'每周崇拜 (Sunday Service) - 星期六'}
              </Typography>
              </Grow>
              <Grow in={true} timeout={2500}>
              <Typography variant="h5"  align="left" >
                  {'7:45pm - 9:30pm'}
              </Typography>
              </Grow>
  
            </Grid>
            <Grid item xs={12} md={7}>
              
                <Grow in={true} timeout={2000}>
      
                <img
                      src="/assets/about.jpg"
                      style={{ flex: 1, width: '100%', borderRadius: 5}}
                      alt="about us"
                    />
                  
                </Grow>
              
            </Grid>
          </Grid>
          
          
          {/*<ReactPlayer width={"100%"} height={"100%"} className={classes.media} controls url='https://www.facebook.com/pgkmediagroup/videos/399641854260637/' />*/}
          
        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
