import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  awardpgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  media_category_bottom: {
    height: 350,
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  },
});





class Index extends React.Component {
  

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container className={classes.container}>
        
          <Grid container spacing={5}>
            
            
            <Grid item xs={12} md={12}>
                
                  <Typography marked="left" align="left" variant="h4" className={classes.maintitle} component="h2">
                  依然要坚强
                  </Typography>
                  
                  <Typography variant="h5"  align="left" >
                  丽萨（匿名），是一名在马来西亚工作的印尼籍劳工，也是位单亲妈妈，育有3个孩子。由于他们的身份并非马来西亚公民，所以丽萨的孩子们不能到政府学校求学，只能在昂贵的私立学校就学。为了让孩子们都有机会接受教育，丽萨便选择一天做两份工。
                  </Typography>

                  <Typography style={{ marginTop: 20}} variant="h5"  align="left" >
                  2020年，疫情爆发初期，大马政府实施“行动管制令”。这导致丽萨失去了一份工作，而另一份工作的工资也强制减半。先别提要缴交孩子们的学费，丽萨一家生活基本开销都快入不敷出了。美里7979全人关怀站（美里福音堂）得知丽萨一家的情况，便帮她申请7979急难救助金，先偿还一部分学费，好让孩子能继续求学。与此同时，志工也辅助他们基本的食物需要。
                  </Typography>

                  <Typography style={{ marginTop: 10}} variant="h5"  align="left" >
                  解决了孩子们上学的问题，丽萨开始在网上售卖食物，然后晚上继续工作，以维持家里的经济开销。无论面对的困难如何，身为妈妈的丽萨依然坚强地面对生活。教会的关心更是让他们有了再次步入正轨的力量与勇气。
                  </Typography>
                  

                  <Typography style={{ marginTop: 30 }} variant="h6"  align="left" >
                  陷入困境依然要坚强生活🌿
                  </Typography>
                  <Typography variant="h6"  align="left" >
                  及时关怀援助走出低潮谷👣
                  </Typography>
                  
                  <Typography style={{ marginTop: 30 }} variant="h6"  align="left" >
                  📌更多资讯
                  </Typography>

                  <Typography style={{ marginTop: 10}} variant="h5"  align="left" >
                  网站： 
                    <Link href="https://www.7979.org.my/" color="inherit" target="_blank">
                        {'7979.org.my'}
                    </Link>
                  </Typography>
                  <Typography style={{ marginTop: 10}} variant="h5"  align="left" >
                  脸书： 
                  <Link href="https://facebook.com/7979onFB" color="inherit" target="_blank">
                        {'facebook.com/7979onFB'}
                    </Link>
                  </Typography>
  
            </Grid>
          </Grid>
          
          
          {/*<ReactPlayer width={"100%"} height={"100%"} className={classes.media} controls url='https://www.facebook.com/pgkmediagroup/videos/399641854260637/' />*/}
          
        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
