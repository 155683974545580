import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  awardpgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  media_category: {
    height: 140,
  },
  media_category_bottom: {
    height: 200,
  },
});





class Index extends React.Component {
  

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container className={classes.container}>
        
        <Typography variant="h6">
                Coming soon...
              </Typography>

        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
