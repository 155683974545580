import React from 'react';
import Grid from '@material-ui/core/Grid'
import HTMLFlipBook from 'react-pageflip';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default function MyBook(props) {

    const [totalPages, setTotalPages] = React.useState(14);
    const [pageNumber, setPageNumber] = React.useState(0);
    const book = React.useRef();
    const renderPage = () => {
        var rows = [];
        for (var i = 0; i < 14; i++) {
            rows.push(<img key={i} src={window.location.href +"/page" + (i+1) + ".jpg"} alt={"page" + (i+1)}></img>);
        }
        return rows;
    }

    const onFlip = React.useCallback((e) => {
        console.log('Current page: ' + e.data);
        setPageNumber(e.data)
    }, []);

    return (
        <Grid style={{ overflowX: "hidden", overflowY: "hidden", alignItems:"center", display:"flex", justifyContent: "center", flexDirection: "column",  maxWidth: 1000}}>
            <HTMLFlipBook  style={{ padding: 0 }} ref={book} onFlip={onFlip} width={400} height={562} size={"fixed"}>
                {renderPage()}
            </HTMLFlipBook>

            <Grid  style={{ display:"flex", justifyContent: "center", flexDirection: "column"  }}>
              <Grid style={{ display:"flex", justifyContent: "center", marginTop: 10}}>
                <Typography variant="caption">
                Page {pageNumber === 0 ? 1 :  pageNumber +1 || (totalPages ? 1 : '--')} of {totalPages || '--'}
                </Typography>
              </Grid>
            <Grid>
                <Button
                style={{ width: 100, margin: 10 }}
                disableElevation
                variant="contained"
                onClick={() =>
                    book.current.pageFlip().flipPrev()}
                >
                Previous
                </Button>
                <Button
                style={{ width: 100, margin: 10 }}
                disableElevation
                variant="contained"
                onClick={() =>
                    book.current.pageFlip().flipNext()}
                >
                Next
                </Button>
            </Grid>
          </Grid>
        </Grid>
        
    );
}