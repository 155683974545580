import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
});


class Index extends React.Component {

    render(){
      const { classes } = this.props;
        return (
            
          <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
            <Container className={classes.container}>
              <Grid >
              <Typography marked="center" align="left" variant="h4" className={classes.maintitle} component="h2" style={{ fontSize: 24}}>
              了解更多资讯
            </Typography>
              </Grid>
            
            </Container>
          </section>);
    }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
