import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  awardpgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  media_category_bottom: {
    height: 350,
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  },
});





class Index extends React.Component {
  

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container className={classes.container}>
        
          <Grid container spacing={5}>
            
            
            <Grid item xs={12} md={12}>
                
                  <Typography marked="left" align="left" variant="h4" className={classes.maintitle} component="h2">
                  异象
                  </Typography>
                  <Typography align="left" variant="h5" style={{ fontStyle: 'italic'}} >
                  神关心的是全世界，祂是宣教的神，我们最好成为有宣教使命的人及教会。
                  </Typography>
                  <Typography variant="h5"  align="left" style={{ marginTop: 10}}>
                  无论是哪种类型的宣教，最重要的是抓紧一个原则：一切最终的目的是抱持着“荣耀神”的心志，参与“神的宣教”。
                  </Typography>
                  <Typography style={{ fontSize: 30, marginTop: 20}} variant="h6" >
                  宣言
                  </Typography>
                  <Typography variant="h5"  align="left" >
                  传福音领人归主，不是宗教竞争、争地盘、抢人，乃是终极性人文关怀的深度生命教育与灵魂工程，是“圣战”——使人脱离死亡，在福音中得以体现生命的终极性目标、价值、意义与归属。
                  </Typography>

                  <Typography style={{ marginTop: 20}} variant="h5"  align="left" >
                  为传承“大使命”作出最大委身：
                  </Typography>

                  <Typography style={{ marginTop: 10}} variant="h5"  align="left" >
                  •	成为摆上的“福音人”
                  </Typography>
                  <Typography style={{ marginTop: 10}} variant="h5"  align="left" >
                  •	成为活生生的“样本”
                  </Typography>
                  <Typography style={{ marginTop: 10}} variant="h5"  align="left" >
                  •	活出“重生成长，荣神益人”的人生目标与价值观。
                  </Typography>
                  

                  <Typography style={{ marginTop: 30 }} variant="h6"  align="left" >
                  以下参与青年宣教营之后的分享
                  </Typography>
                  <Typography style={{  fontSize: 20,marginTop: 10 }} variant="h5"  align="left" >
                  2018华传青年宣教营
                  </Typography>
                  <Typography style={{  fontSize: 20,marginTop: 10 }} variant="h5"  align="left" >
                  12/11 - 16/11/2018
                  </Typography>
                  <Typography style={{ fontSize: 20, marginTop: 10 }} variant="h6"  align="left" >
                  泰国清迈
                  </Typography>

                  <Typography style={{ fontSize: 30, marginTop: 30 }} variant="h6"  align="left" >
                  岑圓圓
                  </Typography>

                  <Typography style={{ fontSize: 46, marginTop: 30 }} variant="h6"  align="left" >
                  主，我在這裡 
                  </Typography>
                  <Typography style={{ fontSize: 46 }} variant="h6"  align="left" >
                  請差遣我
                  </Typography>

                  <Typography style={{ marginTop: 20 }} variant="h6"  align="left" >
                  若不是因著神的恩典，我不會擁有這個福分來到這裡；
若不是因著神的揀選，我不會有機會參與這次在泰國的青宣營。
我在這5天4夜的營會裡得著滿滿從神而來的收穫和不一樣的屬靈視野，同時也體驗了「與宣教工場零距離接觸」是一個怎麼樣的概念。


                  </Typography>

                  <Typography style={{ marginTop: 20 }} variant="h6"  align="left" >
                  獻心會那晚，當牧者開始發出呼召的時候，我心裡有聲音不斷重複，聖靈不斷用這句話來勉勵與提醒我：「唯有我深知道你……」正如詩篇139：1所說：「耶和華啊，你已經鑒察我，認識我。」
                  </Typography>

                  <Grid container spacing={5}>
                    <Grid item xs={12} md={5}>
                    <Card style={{ marginTop: 30 }}>
                        <CardActionArea>
                          <CardMedia
                            className={classes.media_category_bottom}
                            image="https://firebasestorage.googleapis.com/v0/b/bethany-e504f.appspot.com/o/mission%2Fyuanyuan.jpg?alt=media&token=9fb4e0e9-d21a-4c1d-883f-7f108c442556"
                            title="daily_bread_img"
                          />
                        </CardActionArea>
                      </Card>
                      </Grid>
                    
                    <Grid item xs={12} md={7}>
                    <Typography style={{ marginTop: 30 }} variant="h6"  align="left" >
                    我回應神說：
                    </Typography>

                    <Typography style={{ marginTop: 20 }} variant="h6"  align="left" >
                    「主，我在這裡，請差遣我！縱然我不清楚前方的道路如何，也還不知道神在我身上的旨意和呼召到底是什麼，但我很肯定知道神必定會帶領我走這條人生路。求神幫助我，讓我曉得你心意，成為合乎主用的器皿。」
                    </Typography>

                    <Typography style={{ marginTop: 20 }} variant="h6"  align="left" >
                    當晚就這樣單純地相信和順服神的吩咐，將自己奉獻給神。我深知道因祂那豐盛的應許，主必供應我一切所需。神也會親自引領我走祂要我走的路。
                    </Typography>
                
                    <Typography style={{ marginTop: 20, marginBottom: 20 }} variant="h6"  align="left" >
                    為己無所求，為主無所留！我們必須留心聆聽神給的吩咐並且在裝備自己和能力提升方面，都要有一顆願意謙卑、歸零的心。這趟泰國青宣營也讓我看見服事神一定要把握好時機。繼續依靠主，為主而活。因祂配得一切的榮耀與讚美。
                    </Typography>
                
                    <Typography style={{ marginTop: 30 }} variant="caption">
                    取自：华传https://www.gointl.org/2018missioncamp.php


                    </Typography>
                    </Grid>
                  </Grid>
                  
  
            </Grid>
          </Grid>
          
          
          {/*<ReactPlayer width={"100%"} height={"100%"} className={classes.media} controls url='https://www.facebook.com/pgkmediagroup/videos/399641854260637/' />*/}
          
        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
