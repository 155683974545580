import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  iconsWrapper: {
    height: 100,
  },
  icons: {
    display: 'flex',
  },
  icon: {
    borderRadius: 5,
    width: 48,
    height: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: "#191919",
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#2a2a2a",
    },
  },
  list: {
    margin: 0,
    listStyle: 'none',
    padding: 0,
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  language: {
    marginTop: theme.spacing(1),
    width: 150,
  },
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={12}>
            <Grid
              container
              direction="column"
              justify="flex-end"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons}>
                <a href="https://www.facebook.com/bcckt" className={classes.icon}>
                  <img src="/assets/appFooterFacebook.png" alt="Facebook" />
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
          <Typography style={{ fontSize: 14, marginTop: 10, textTransform: 'none' }} variant="h6" >
              <Link href="/about">关于我们</Link>
            </Typography>
            
          </Grid>
        
          <Grid item>
            <Typography variant="caption">
            本站特告：杜绝任何涉及政治话题、异端邪教影片，如您发现本站有不符合圣经的依据的影片请通知告知我们会立即删除
视频版权声明：本站提供的所有福音视频资源均来自网上,所有版权皆属原创者所有,如果认为本站侵犯了您的版权,请告知我们会立即删除
本站提供的福音视频是以高举耶稣基督并他钉十字架的福音真理为核心，减少异端邪教的萌发，请各肢体为本站事工代祷！
设计版权声明：本网站所有应用设计（包括框架，颜色，图案），功能及其排版形式，均系原创并附有原稿受到著作权权法保护，谢绝复制转载！
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              2020 © Copyright BCCKT. All Rights Reserved
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}