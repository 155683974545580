import React from 'react';
import Grid from '@material-ui/core/Grid'
import { Document, Page } from 'react-pdf';
import Typography from '@material-ui/core/Typography';
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import Button from '@material-ui/core/Button';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Test(props) {
  const [numPages, setNumPages] = React.useState(1);
  const [pageNumber, setPageNumber] = React.useState(1);
  const pdfWrapperRef = React.useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);

  }

  const previousPage = () => {
      if(pageNumber > 1){
        setPageNumber(prevPageNumber => prevPageNumber - 1);
      }
    
  }

  const nextPage = () => {

    if(pageNumber < numPages){
        setPageNumber(prevPageNumber => prevPageNumber + 1);
    }
    
  }

  return (
    <Grid style={{ display:"flex", justifyContent: "center", flexDirection: "column", maxWidth: 500}}  ref={pdfWrapperRef}>
        <Grid style={{ display:"flex", justifyContent: "center"}}>
                <Document
                file={"assets/BCC_60.pdf"}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={pageNumber} width={
            pdfWrapperRef.current?.getBoundingClientRect().width ||
            undefined
          }/>
                </Document>
        </Grid>
      
        <Grid onClick={previousPage} style={{ opacity: .5, display :pageNumber <= 1 ? "none": "flex", position: "absolute", backgroundColor: "transparent", 
        width: pdfWrapperRef.current?.getBoundingClientRect().width/2 ||
        undefined, 
        height: "100%", top: 0 }}>
        </Grid>
        <Grid onClick={nextPage} style={{ opacity: .5, display :pageNumber >= numPages ? "none": "flex", position: "absolute", backgroundColor: "transparent", 
        marginLeft: pdfWrapperRef.current?.getBoundingClientRect().width/2 ||
        undefined,
        width: pdfWrapperRef.current?.getBoundingClientRect().width/2 ||
        undefined, 
        height: "100%", top: 0 }}>
        </Grid>
      <Grid style={{ display:"flex", justifyContent: "center"  }}>
          <Grid  style={{ display:"flex", justifyContent: "center", flexDirection: "column"  }}>
              <Grid style={{ display:"flex", justifyContent: "center"}}>
                <Typography variant="caption">
                Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                </Typography>
              </Grid>
            <Grid>
                <Button
                style={{ width: 100, margin: 10 }}
                disableElevation
                variant="contained"
                onClick={previousPage}
                >
                Previous
                </Button>
                <Button
                style={{ width: 100, margin: 10 }}
                disableElevation
                variant="contained"
                onClick={nextPage}
                >
                Next
                </Button>
            </Grid>
          </Grid>
          
      </Grid>
      
    </Grid>
  );
}