import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import AppBar from '../components/AppBar';
import Toolbar, { styles as toolbarStyles } from '../components/Toolbar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '../components/Button';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuList from '@material-ui/core/MenuList';
import * as Scroll from 'react-scroll';
import Typography from '../components/Typography';
import Collapse from '@material-ui/core/Collapse';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';

const styles = (theme) => ({
  title: {
    fontSize: 18,
    lineHeight: 1,
    marginRight: theme.spacing(3),
  },
  titleLarge: {
    fontSize: 28,
    lineHeight: 1,
    marginRight: theme.spacing(0),
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: 'space-between',
    backgroundColor: "#f5f5f5",
  },
  left: {
    flex: 1,
  },
  leftLinkActive: {
    color: theme.palette.common.white,
  },
  right: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rightLink: {
    fontSize: 14,
    color: "#000000",
    '&:hover': {
      color: theme.palette.warning.dark,
    },
  },
  rightColorLink: {
    color: theme.palette.warning.dark,
  },
  linkSecondary: {
    color: theme.palette.warning.dark,
  },
  image: {
    height: 45,
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  drawertxt: {
    fontSize: 14,
    flex: 1,
    display: 'flex',
    color: "white",
  },
  drawertxtSecondary: {
    color: theme.palette.warning.main,
    fontSize: 14,
    flex: 1,
    display: 'flex',
    borderBottomWidth: .5,
    borderBottomColor: theme.palette.warning.main,
    borderBottomStyle: 'solid'
  },
  drawerImage: {
    height: 45,
  },
  drawerImageMc: {
    flex: 1,
    display: 'flex',
    marginTop: theme.spacing(1),
    marginLeft: 20,
  },
  drawerlist: {
    width: 150,
    paddingLeft: 20,
  },
  icon: {
    opacity: .5,
    '&:hover': {
      opacity: 0.8,
    },
  },
  aboutmenu: {
    opacity: 1,
    '&:hover': {
      opacity: 0.3,
    },
  }
  
});



class AppAppBar extends Component {

  state = {
    isMobile: false,
    openDrawer: false,
    aboutOpen: false,
    networkOpen: false,
    anchorEl: null,
    anchorElNetwork: null,
    youtvDrop: false,
    moreOpen: false,
    selectedMonth: new Date().getMonth()+1,
    selectedDay: new Date().getDate(),
    selectedYear: new Date().getFullYear(),
  }

  UNSAFE_componentWillMount(){
    this.handleResize();
    window.addEventListener("resize", this.handleResize );
    window.scrollTo(0, 0);
  }

  handleResize  = () => {
    const windowSize = window.innerWidth;
    
    if(windowSize < 1200){
      this.setState({ isMobile: true })
    } else {
      this.setState({ isMobile: false, openDrawer: false })
    }
  }

  toggleDrawer  = (open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ openDrawer: open });
  }

  leftMenuClick = () => {
    this.setState({ aboutOpen: !this.state.aboutOpen  })
  }

  networkClick = () => {
    this.setState({ networkOpen: !this.state.networkOpen  })
  }
  
  handleAboutClose = () => {
    this.setState({ anchorEl: null })
  }

  handleAboutMenuClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleNetworkClose = () => {
    this.setState({ anchorElNetwork: null })
  }

  handleNetworkMenuClick = event => {
    this.setState({ anchorElNetwork: event.currentTarget })
  }

  scrollToPartners = () => {
    var scroller = Scroll.scroller;

    scroller.scrollTo('Campaigns', {
      duration: 1000,
      smooth: true,
      offset: -60,
    })

    this.setState({ anchorEl: null, openDrawer: false })
  }

  scrollToAbout = () => {
    var scroll     = Scroll.animateScroll;
  
      scroll.scrollToTop();

    this.setState({ anchorEl: null, openDrawer: false })
  }

  scrollToNews = () => {
    var scroller = Scroll.scroller;

    scroller.scrollTo('News', {
      duration: 1000,
      smooth: true,
    })

    this.setState({ anchorEl: null, openDrawer: false })
  }

  scrollToAwards = () => {
    var scroller = Scroll.scroller;

    scroller.scrollTo('Awards', {
      duration: 1000,
      smooth: true,
      offset: -60,
    })

    this.setState({ anchorEl: null, openDrawer: false })
  }

  scrollToNetworks = () => {
    var scroll     = Scroll.animateScroll;
  
    scroll.scrollToTop();

    this.setState({ anchorElNetwork: null, openDrawer: false })
  }

  scrollToValueProposition = () => {
    var scroller = Scroll.scroller;

    scroller.scrollTo('ValueProposition', {
      duration: 1000,
      smooth: true,
      offset: -60,
    })

    this.setState({ anchorElNetwork: null, openDrawer: false })
  }

  scrollToValueAdvertisers = () => {
    var scroller = Scroll.scroller;

    scroller.scrollTo('Advertisers', {
      duration: 1000,
      smooth: true,
      offset: -60,
    })

    this.setState({ anchorElNetwork: null, openDrawer: false })
  }

  youtvDrop = () => {
    this.setState({ youtvDrop: !this.state.youtvDrop })
  }

  render() {
    const { classes } = this.props;

    const selected_date = this.state.selectedDay.toString().padStart(2,0) + this.state.selectedMonth.toString().padStart(2,0)+ this.state.selectedYear.toString();
    
    const list = (anchor) => (
      <div style={{ backgroundColor: '#910c21', height: "100%"}}>
        
        <List style={{ marginTop: 50}}>
            <ListItem button key={"Home"} className={classes.drawerlist} component="a" href={"/"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"主页"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"DailyBread"} className={classes.drawerlist} component="a" href={"/manna/" + selected_date}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"默想与回应"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"sharemusic"} className={classes.drawerlist} component="a" href={"/sharemusic"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"诗歌分享"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"bibletest"} className={classes.drawerlist} component="a" href={"/bibletest"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"读经问答"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"book"} className={classes.drawerlist} component="a" href={"/book"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"好书介绍"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"pray"} className={classes.drawerlist} component="a" href={"/pray"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"彼此代祷"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"caring"} className={classes.drawerlist} component="a" href={"/caring"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"关怀行动"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"mission"} className={classes.drawerlist} component="a" href={"/mission"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"差传资讯"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>
            <ListItem button key={"about"} className={classes.drawerlist} component="a" href={"/about"}>
              <ListItemText align="left" primary={<Link
                  color="inherit"
                  variant="h6"
                  underline="none"
                  className={classes.drawertxt}
                >
                  {"关于我们"}
                </Link>} className={classes.drawertxt} />
                
            </ListItem>

            <ListItem key={"SOCIAL MEDIA"} component="a" style={{ marginTop: 20}} className={classes.drawerlist}>
                <Link 
                      href="https://www.facebook.com/bcckt"
                    >
                  <img style={{ height: 25, width: 25 }} src="/assets/appFooterFacebook_white.png" alt="facebook" />
                </Link>
            </ListItem>
           
        </List>
      </div>
    );
  


    return (
      <div>
        <AppBar position="fixed">
          <Toolbar className={classes.toolbar}>

            <div className={classes.left} />
              <Link href="/">
                <img
                    className={classes.image}
                    src="/assets/Logo.png"
                    alt="bethany-logo"
                  />
              </Link>
              { this.state.isMobile ? 

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.toggleDrawer(true)}
                  edge="start"
                  style={{ position: 'absolute', justifyContent: 'center', alignItems: 'center', right: 10}}
                >
                  <MenuIcon  style={{ color: "#000000" }} />
                </IconButton>
              :  <div >
                <Button
                style={{ padding: 20, marginLeft: 30}}
                  className={this.props.page === "dailybread" ? classes.rightColorLink : classes.rightLink}
                  href={"/manna/" + selected_date}
                >
                   {'默想与回应'}
                </Button>
                
                <Button
                style={{ padding: 20}}
                  className={this.props.page === "sharemusic" ? classes.rightColorLink : classes.rightLink}
                  href="/sharemusic"
                >
                  {'诗歌分享'}
                </Button>
                <Button
                style={{ padding: 20}}
                  className={this.props.page === "bibletest" ? classes.rightColorLink : classes.rightLink}
                  href="/bibletest"
                >
                  {'读经问答'}
                </Button>
                <Button
                style={{ padding: 20}}
                  className={this.props.page === "book" ? classes.rightColorLink : classes.rightLink}
                  href="/book"
                >
                  {'好书介绍'}
                </Button>
                <Button
                style={{ padding: 20}}
                  className={this.props.page === "pray" ? classes.rightColorLink : classes.rightLink}
                  href="/pray"
                >
                  {'彼此代祷'}
                </Button>
                <Button
                style={{ padding: 20}}
                  className={this.props.page === "caring" ? classes.rightColorLink : classes.rightLink}
                  href="/caring"
                >
                  {'关怀行动'}
                </Button>
                <Button
                style={{ padding: 20}}
                  className={this.props.page === "mission" ? classes.rightColorLink : classes.rightLink}
                  href="/mission"
                >
                  {'差传资讯'}
                </Button>
                <Button
                style={{ padding: 20}}
                  className={this.props.page === "about" ? classes.rightColorLink : classes.rightLink}
                  href="/about"
                >
                  {'关于我们'}
                </Button>
                <Link
                style={{ padding: 0}}
                  href="https://www.facebook.com/bcckt"
                >
                  <IconButton className={classes.icon} aria-label="Facebook" style={{ marginLeft: 30, padding: 10,  color: "white" }}>
                    <img style={{ height: 20, }} src="/assets/appFooterFacebook_dark.png" alt="Linkedln" />
                  </IconButton>
                </Link>
              </div>}
            <div className={classes.left}>
              
            </div>
          </Toolbar>
        </AppBar>
        <div className={classes.placeholder} />
        <SwipeableDrawer
            anchor={'right'}
            open={this.state.openDrawer}
            onClose={this.toggleDrawer(false)}
            onOpen={this.toggleDrawer(true)}
            disableBackdropTransition
          >
          {list('left')}
        </SwipeableDrawer>
      </div>
      );
    }
}

AppAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppAppBar);
