import React, { Component } from 'react';
import withRoot from '../modules/withRoot';
import AppAppBar from '../modules/views/AppAppBar';
import BibleTest from '../modules/views/BibleTest';
import TopBanner from '../modules/views/TopBanner';
import Footer from '../modules/views/Footer';

class Index extends Component {
    render() {
      return (
        <React.Fragment>
          <AppAppBar page="bibletest"/>
          <TopBanner />
          <BibleTest/>
          <Footer />
        </React.Fragment>
      );
    }
}
  
export default withRoot(Index);