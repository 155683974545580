import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import Button from '@material-ui/core/Button';
import ReactAudioPlayer from 'react-audio-player';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import ReactPlayer from 'react-player/youtube'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(5),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: '20%',
    [theme.breakpoints.down('md')]: {
      width: '25%',
    },
  },
  media_category_bottom: {
    height: 350,
    [theme.breakpoints.down('md')]: {
      height: 200,
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


class Index extends React.Component {

  state={
    value: 0
  }
  

  handleChange = (event, newValue) => {
    this.setState({ value : newValue })
  }

  render(){

    const { classes } = this.props;

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container className={classes.container}>
        
          <Grid container spacing={5}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={this.state.value}
              onChange={this.handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
            >
              <Tab label="三一颂" {...a11yProps(0)} />
              <Tab label="赞美诗 287" {...a11yProps(1)} />
              <Tab label="赞美诗 73" {...a11yProps(2)} />
              <Tab label="回家的圣诞" {...a11yProps(3)} />
            </Tabs>
            <TabPanel style={{ width: '70%'}} value={this.state.value} index={0}>
              <Typography variant="h4" style={{ marginTop: 0, color: "#910c21" }}>
                {"三一颂 (你知多少?)"}
              </Typography>
              <ReactPlayer width={"100%"} height={"250px"} style={{ marginTop: 20 }}controls url='https://www.youtube.com/watch?v=OQxY-EIALSY' />
              <Typography variant="h5"  style={{  fontSize: 14, marginTop: 10 }}>
                {"这段视频里的音乐是管风琴的演奏，前两分钟是即兴演奏，最后以伴奏会众唱三一颂来结束。"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"在过去的300余年，这首只有短短四行的圣诗成为了一首脍炙人口的伟大圣诗。它被翻译成无数的语言，每个星期天在各宗派、世界各地的教会颂唱。"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 15 }}>
                {"不同教会会在崇拜不同的时段颂唱三一颂，较常见的有：在收取奉献后，以及在崇拜结束和领受祝福前。"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 15 }}>
                {"我们对这首圣诗的歌词和曲调是再熟悉不过。但，这首诗歌为何在崇拜中要唱、如何写成，其背景和意义却鲜少有人知道。"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 15 }}>
                {"《三一颂》的英文Doxology，源自古希腊文Doxologia。”Doxa”意为荣耀、颂赞，;“-logia”则是告诉、述说。因此，Doxology一词指的就是对神 赞美的表达。《三一颂》是一首信徒赞美三位一体真神的圣诗。"}
              </Typography>
              <Typography variant="h6" style={{ marginTop: 15 }}>
                {"三一颂的歌词和曲调分别由不同人所作。"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0, color: "#041842" }}>
                {"三一颂的作词人是简多马主教(Thomas Ken 1637-1711)。简主教在1674年出版了“温彻斯特学院学者祈祷手册”，并在1695版时将三一颂的歌词，加入到他所写的日晨颂和晚祷颂   其中三首诗歌  的最后一节。"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 15, color: "#156e00" }}>
                {"三一颂的曲调则是采用路易斯•布儒瓦(Louis Bourgeois 1510-1561) 所谱写的《日內瓦诗篇集》第100首：Old Hundredth曲调。这曲调颇为人知，在15世纪时乃是赞美诗《称谢歌》的曲调  。后期才将此曲调应用在简主教所写的歌词。我们所熟悉的《三一颂》就这样形成了。"}
              </Typography>

              <Typography variant="h6" style={{ marginTop: 30 }}>
                {"赞美真神 万福之源"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"世上万民都当颂扬"}
              </Typography>
              <Typography variant="h6" style={{ marginTop: 0 }}>
                {"天使 天军 赞美主名"}
              </Typography>
              <Typography variant="h6" style={{ marginTop: 0 }}>
                {"赞美 圣父 圣子 圣灵 阿们"}
              </Typography>

              <Typography variant="h5"  style={{ marginTop: 15 }}>
                {"我们每周在崇拜接近尾声时颂唱《三一颂》时，"}
              </Typography>
              <Typography variant="h5" style={{ marginTop: 0 }}>
                {"• 它犹如强而有力的总结，将基督徒对三位一体真神的赞美、感谢、赞叹、敬拜集为一体，献给三一神。"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0 }}>
                {"• 它帮助我们重新定睛于三位一体的上帝，仰望祂，认定神的作为；更是凭信心相信神的恩典继续与我们同在，我们可以满有平安地踏出礼拜堂继续服事祂。 "}
              </Typography>

              <Typography variant="h6" style={{ marginTop: 15 }}>
                {"盼望您在下一次再唱《三一颂》时，不再是机械式的颂唱，而是心怀感恩赞美上帝。"}
              </Typography>

              <Typography variant="h5"  style={{ fontSize: 14, marginTop: 15 }}>
                {"• 日晨颂和晚祷颂是日常礼赞时会唱的圣诗。日常礼赞乃是英国圣公会的操练，源于中世纪的修道院的祷告传统。信徒以诵读诗篇、经课，颂唱圣诗及祈祷，进行早祷崇拜与晚祷崇拜。"}
              </Typography>
              <Typography variant="h5" style={{ fontSize: 14, marginTop: 0 }}>
                {"• “Awake, My Soul, and with the Sun,” “All Praise to Thee, My God, This Night,” 及 “My God, I Now from Sleep Awake” "}
              </Typography>
              <Typography variant="h5" style={{ fontSize: 14, marginTop: 0 }}>
                {"• 可参阅《赞美诗6称谢歌》"}
              </Typography>
              <Typography variant="h5"  style={{ fontSize: 14, marginTop: 0 }}>
                {"• 曾浩斌。（用《三一颂》来敬拜主）。《真理报》。卷19，期5（2011年5月）：页15。"}
              </Typography>
              
            </TabPanel>
            <TabPanel value={this.state.value} style={{ width: '70%'}} index={1}>
              <Typography variant="h4" style={{ marginTop: 0, color: "#910c21" }}>
                {"赞美诗287《我灵镇静》"}
              </Typography>
              <ReactPlayer width={"100%"} height={"250px"}  style={{ marginTop: 20 }}controls url='https://youtu.be/mNLFFXkIvi4 ' />
              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"我灵，镇静！主正在你身旁，忧伤十架，务要忍耐担当；"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"信靠父神，由祂安排主张，万变之中，惟主信实久长；"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"我灵，镇静！天友最是善良，经过荆棘，引到欢乐之疆。"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"我灵，镇静！一切有主担当，未来引导，必与过去一样；"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"任何遭遇，莫让信、望动摇，眼前隐祕终必成为明朗；"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"我灵，镇静！风浪仍在听命，有如当年主使风浪平静。"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"我灵，镇静！时间过去何速，即将与主永远同在一处；"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"失望、惊恐，那时远远离我，爱何无穷，忘记一切忧伤；"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"我灵，镇静！当主擦我眼泪，平安满溢，当在天家相逢。"}
              </Typography>

              <Typography variant="h5"  style={{ marginTop: 20 }}>
                {"这首圣诗的歌词原著是德文，作者是一位德国的女作家施莱格尔 （Katharina von Schlegel, 1697-1765）。施莱格尔写了许多诗，但大部份诗都未被译成英文。"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 15 }}>
                {"直到约一百年后，有一位苏格兰自由教会会友--卜薇（Jane L. Borthwick, 1813-1897）才将这首诗翻译成英文。《我灵镇静》原诗有六节，但只有其中五节被翻译成英文；流传至今，只剩3节被广泛使用。"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 15 }}>
                {"谱曲者则是芬兰最著名的作曲家西贝流士（Jean Sibelius, 1865-1957），此曲谱于1899年。"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"这首庄严、肃穆、稳重、宁静的圣诗，在抗战时期，使许多基督徒得安慰与力量。"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"在如此动荡不安、危机四处的世界，盼望这首诗歌，也成为我们今日的安慰。"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"我们知道今生有主引导与眷顾，来世有永远的喜乐和荣耀。"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"默想经文："}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0 }}>
                {"【你们要住手，要知道我是神】诗篇46:1a "}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0 }}>
                {"【你们得救在于悔改和安息，你们得力在于平静和信靠。】赛30:15"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0 }}>
                {"【然后，我们还活着存留的人，必和他们一同被提到云里，在空中与主相会。这样，我们就要和主常常同在。】帖前4:17 "}
              </Typography>

              <Typography variant="h5"  style={{ fontSize: 14, marginTop: 20 }}>
                {"祷告：“哦！光明的父，祢是坚定不变的，正如天上的繁星；赐给我们恩典，叫我们在祢永恒不变中安息；在祢信实中寻找平安。”--乔治•华伦斯•布里格斯"}
              </Typography>
            </TabPanel>
            <TabPanel value={this.state.value} style={{ width: '70%'}} index={2}>
              <Typography variant="h4" style={{ marginTop: 0, color: "#910c21" }}>
                {"宁静又荣耀的夜晚"}
              </Typography>
              <Typography variant="h4" style={{ marginTop: 0, color: "#910c21" }}>
                {"赞美诗73《美哉小城，小伯利恒》"}
              </Typography>
              <ReactPlayer width={"100%"} height={"250px"}  style={{ marginTop: 20 }}controls url='https://youtu.be/196EvS9ohbM' />
              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"单簧管独奏，搭配少许的爵士乐风格，轻柔地描绘了2000多年前伯利恒那宁静与荣耀交汇的夜晚。这是笔者最喜欢的现代作曲家—Dan Forrest所编曲的《美哉小城，小伯利恒》，由美国Salt Lake Vocal Artists演唱。"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"美哉小城 小伯利恒 你是何等清静"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"无梦无惊 深深睡着 群星悄然进行"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"在你漆黑的街衢 永远的光昭启"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"万世所求 万人所望 今宵集中于你"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"因马利亚诞生圣婴 天上天军齐集"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"世界众生 酣睡正深 天军虔敬守夜"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"晨星啊 同来颂扬 同来礼拜虔诚"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"同来感谢至尊上主 同祝世界和平"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"求伯利恒至圣婴孩 现在仍复降临"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"驱除我罪 进我胸怀 今日生於我心"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"我听圣诞之天使 宣告大喜信息"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"求在我心永远长住 我主以马内利"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"歌曲背景："}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0 }}>
                {"菲利•布鲁克斯牧师在美国费城圣三一主教教堂牧会时，被要求为主日学圣诞庆祝会写一首圣诞诗歌。他不禁开始思考：“我该写什么？我要如何用诗歌激发主日学学生思考基督降生——祂为何卑微降生于马槽？祂降生的理由是什么？祂的降生与我们的重生有何关系？”"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 15 }}>
                {"经过祷告以后，他感受到上帝引导他回想起他三年前到访圣地时的经历。那时，是圣诞节前夕，他站在山坡上遥望夜晚沉静的伯利恒。一对惊慌失措的夫妇正在寻找旅馆的画面，出现在他的脑海中。他看到没有旅馆有空间收留他们。不久之后，他又仿佛看见天使向牧羊人宣告：“不要惧怕，我报给你们大喜的信息，是关乎万民的；因今天在大卫的城里，为你们生了救主，就是主基督。你们要看见一个婴孩，包着布，卧在马槽里，那就是记号了。”又看见天使天军赞美神说：““在至高之处 荣耀归于神！在地上平安归于他所喜悦的人！”"}
              </Typography>

              <Typography variant="h5"  style={{ marginTop: 15 }}>
                {"他心想：好难过。旅馆竟然没有可以容得下，配得天使赞美的神的儿子；那位原本拥有一切却又为了人舍弃一切，那位愿意为世人所犯的罪担下一切刑罚的婴孩耶稣。"}
              </Typography>

              <Typography variant="h5"  style={{ marginTop: 15 }}>
                {"布鲁克斯牧师把他的思绪整理成诗歌歌词。之后即可找到教会的风琴手--路易斯•雷德纳为此谱曲。直到是圣诞节前夕，雷德纳仍然没有头绪，他十分沮丧地就寝。就在他睡下以后，他梦见了天使歌唱的情景，因此他又醒了过来，他认为这是神所赐的旋律，就将其写下来。后而，这首诗歌也流传至今，成为圣诞节不可或缺的一首圣诗。"}
              </Typography>

            </TabPanel>
            <TabPanel value={this.state.value} style={{ width: '70%'}} index={3}>
               <Typography variant="h4" style={{ marginTop: 0, color: "#910c21" }}>
                {"回家的圣诞"}
              </Typography>
              <ReactPlayer width={"100%"} height={"250px"}  style={{ marginTop: 20 }}controls url='https://www.youtube.com/watch?v=cukMnhtZYe0 ' />
              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"词:龚倩芬、曲:陶晓芸、编曲: 胡本琳"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"白雪季节 冷空气充满温暖"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"湛蓝天空 映照洗涤心灵"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"终于放下 沉重行李 把昨天留在门外"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"将我的心交还给主"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"离家的那些圣诞 曾那样空白"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"梦醒残留孤寂 追寻永无止境"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"耶稣的爱 让回家不再遥远"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"耶稣的爱 让我的心不再飘零"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"回家的今年圣诞 竟如此幸福"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"这遗忘的单纯 这安心的归宿"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"耶稣的爱 让回家不再遥远"}
              </Typography>
              <Typography variant="h6"  style={{ marginTop: 0 }}>
                {"耶稣的爱 让我的心不再飘零"}
              </Typography>

              <Typography variant="h6"  style={{ marginTop: 20 }}>
                {"反思："}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0 }}>
                {"看着满街的圣诞装饰，播着圣诞歌的商场，忙着抢购打折商品的人们；我们知道圣诞节就在眼前。每一年的圣诞节，我们都很忙碌；有的人忙于教会事奉，有的人忙于生意工作，有的人忙于逃避上帝。"}
              </Typography>
              <Typography variant="h5"  style={{ marginTop: 0 }}>
                {"正如这首诗歌所说，离开上帝的日子是如此地迷茫。盼望我们在圣诞节静下来想一想，今年的圣诞节你“回家”了吗？"}
              </Typography>

            </TabPanel>

          </Grid>
          
          
          {/*<ReactPlayer width={"100%"} height={"100%"} className={classes.media} controls url='https://www.facebook.com/pgkmediagroup/videos/399641854260637/' />*/}
          
        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
