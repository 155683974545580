import firebase from "firebase";

//youtv
const app = firebase.initializeApp({
    apiKey: "AIzaSyBNQtnXQfmSb4sv5REFB8d6HKeXA6J9WT0",
    authDomain: "bethany-e504f.firebaseapp.com",
    databaseURL: "https://bethany-e504f.firebaseio.com",
    projectId: "bethany-e504f",
    storageBucket: "bethany-e504f.appspot.com",
    messagingSenderId: "190844853908",
    appId: "1:190844853908:web:f0eb64265a384d6cfede81",
    measurementId: "G-J4GXDZ4V7B"
});



export default app;

export const firebaseAnalytics = firebase.analytics();