import React, { Component } from 'react';
import withRoot from '../modules/withRoot';
import AppAppBar from '../modules/views/AppAppBar';
import Pray from '../modules/views/ComingSoon';
import TopBanner from '../modules/views/TopBanner';
import Footer from '../modules/views/Footer';

class Index extends Component {
    render() {
      return (
        <React.Fragment>
          <AppAppBar page="book"/>
          <TopBanner />
          <Pray/>
          <Footer />
        </React.Fragment>
      );
    }
}
  
export default withRoot(Index);