import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../components/Typography';
import Grid from '@material-ui/core/Grid'
import Grow from '@material-ui/core/Grow';
import app from "../../firebase/base";
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

const styles = (theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: "transparent",
  },
  container: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(9),
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  media: {
    marginTop: theme.spacing(10)
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  maintitle: {
    marginBottom: theme.spacing(0),
  },
  titlepgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  awardpgk: {
    fontWeight: 600,
    fontSize: 24,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    textAlign: "left",
    alignSelf: 'stretch'
  },
  media_category_bottom: {
    height: 350,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      height: "100%",
    },
  },
});





class Index extends React.Component {
  
  state ={
    selectedMonth: new Date().getMonth()+1,
    selectedDay: new Date().getDate(),
    selectedYear: new Date().getFullYear(),
    currentContent: null,
    loading: true,
    selectedMonth: new Date().getMonth()+1,
    selectedDay: new Date().getDate(),
    selectedYear: new Date().getFullYear(),
  }

  componentDidMount(){

    const selected_date = this.state.selectedDay.toString().padStart(2,0) + this.state.selectedMonth.toString().padStart(2,0)+ this.state.selectedYear.toString();
    
    let ref = app.database().ref('daily_bread').child(selected_date);

    ref.once('value', snapshot => {
      
      if(snapshot.exists()){
        this.setState({ currentContent : snapshot.val() , loading: false})

      }
      else
      {
        this.setState({ currentContent : null , loading: false})
      }
    })
  }

  render(){

    const { classes } = this.props;

    const selected_date = this.state.selectedDay.toString().padStart(2,0) + this.state.selectedMonth.toString().padStart(2,0)+ this.state.selectedYear.toString();

    let config = {
      num: [1, 4],
      rps: 0.1,
      radius: [5, 40],
      life: [1.5, 3],
      v: [2, 3],
      tha: [-40, 40],
      alpha: [0.6, 0],
      scale: [.1, 0.4],
      position: "all",
      color: ["#ffc000"],
      // emitter: "follow",
      random: 10,
    };
    
    if (Math.random() > 1) {
      config = Object.assign(config, {
        onParticleUpdate: (ctx, particle) => {
          ctx.beginPath();
          ctx.rect(
            particle.p.x,
            particle.p.y,
            particle.radius * 2,
            particle.radius * 2
          );
          ctx.fillStyle = particle.color;
          ctx.fill();
          ctx.closePath();
        }
      });
    }
    
    return (
      <section className={classes.root} style={{ backgroundColor: !this.props.main ? "white" : "#fff5f8" }}>
        <Container className={classes.container}>
        
        {this.state.loading ? 
        <CircularProgress color="secondary" />: this.state.currentContent ? <Grid container spacing={5}>
            
            <Grid item xs={12} md={12} >
             <Grow in={true} timeout={1000}>
                  <Typography marked="center" align="center" variant="h4" className={classes.maintitle} component="h2">
                  {/*this.state.currentContent ? this.state.currentContent.title : ''*/ '默想与回应'}
                  </Typography>
                </Grow>
            </Grid>
            {this.state.currentContent ? <Grid style={{ display: "flex", justifyContent: "center", marginTop: -15}} item xs={12} md={6}>
              
                <Grow in={true} timeout={2000}>
      
                        <img
                          className={classes.media_category_bottom}
                          src={this.state.currentContent.image}
                          alt="home daily bread image"
                        />

                  
                </Grow>
              
            </Grid> : null}
            <Grid item xs={12} md={6} style={{ marginTop: -15}}>
               
                <Grow in={true} timeout={1500} >
                  <Typography align="left" variant="caption"  style={{ marginTop: 0}}>
                  {'日期： ' + this.state.selectedDay + '日' + this.state.selectedMonth + '月' + this.state.selectedYear + '年'}
                  </Typography>
                </Grow>
                <Grow in={true} timeout={2000}>
                  <Typography variant="h6"  align="left" style={{ marginTop: 5}}>
                  {this.state.currentContent ? this.state.currentContent.bible : ''}
                  </Typography>
                  </Grow>
              <br/>
              <Grow in={true} timeout={2500}>
              <Typography variant="h5"  align="left" style={{ marginTop: -15}}> 
                {this.state.currentContent ? this.state.currentContent.short_content : ''}
              </Typography>
              </Grow>

              <Grow in={true} timeout={3000}>
                <Button variant="outlined" style={{ marginTop: 20}} href={"/manna/" + selected_date}>
                  阅读更多
                </Button>
              </Grow>
  
            </Grid>
            
          </Grid>: 
          <Grid style={{ width: '100%'}}>
            <Typography marked="left" align="left" variant="h4" className={classes.maintitle} component="h2">
                不好意思，您现在的日期暂时没有默想与回应哦...
            </Typography>
          </Grid>}
          

        </Container>
        
      </section>
    );
  }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
