import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import CardMedia from '@material-ui/core/CardMedia';

const styles = (theme) => ({
  image: {
    display: "flex", 
    width: '100%', 
    height: 400,
    [theme.breakpoints.down('md')]: {
      height: 150,
    },
  },
  prevButton: {
    position: 'absolute',
    border: '3px solid white',
    left: 20,
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      border: '2px solid white',
      width: 40,
      height: 40
    },
  },
  nextButton: {
    position: 'absolute',
    border: '3px solid white',
    right: 20,
    zIndex: 99,
    [theme.breakpoints.down('sm')]: {
      border: '2px solid white',
      width: 40,
      height: 40
    },
  },
  icon: {
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: 10,
      height: 10
    }
  },
  bannerTitle: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: 36
    },
  },
  bannerSubTitle: {
    color: theme.palette.secondary.light,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    },
  },
  bannerTitleMc: {
    position: 'absolute',
    paddingRight: 100,
    paddingLeft: 100,
  },

});


class Index extends React.Component {

    state = {
        windowWidth: 0,
        windowHeight: 0,
        currentIndex: 0,
        responsive: { 0: { items: 1 } },
        galleryItems: this.galleryItems(),
    }

    componentDidMount(){
      
        this.handleResize();
        window.addEventListener("resize", this.handleResize );
    }

    handleResize  = () => {

        let ratio = 1920 / window.innerWidth;
        let windowHeight = 490 / ratio;
        
        this.setState({ windowWidth: window.innerWidth, windowHeight })

    }

    galleryItems() {
      
      return Array(3)
        .fill()
        .map((item, i) => <div><img src={"https://firebasestorage.googleapis.com/v0/b/bethany-e504f.appspot.com/o/TopBanner%2Fbg0_1.jpg?alt=media&token=7514981e-4dd9-4f59-b83f-20bc45a42089"} className={this.props.classes.image} alt={"img"+i}/></div>)
    }
   
    slidePrevPage = () => {
      if (this.state.currentIndex <= 0) {
        this.setState({ currentIndex: this.state.galleryItems.length - 1 }) 
      }
      else{
        this.setState({ currentIndex: this.state.currentIndex - 1 })
      }
      
    }
   
    slideNextPage = () => {
      
      if (this.state.currentIndex >= this.state.galleryItems.length - 1) {
        this.setState({ currentIndex: 0 })
      }
      else{
        this.setState({ currentIndex: this.state.currentIndex + 1 })
      }
    }

    onChange = (event) => {

      this.setState({ currentIndex: event })
    }
   
    render(){
        const { classes } = this.props;
        return (
          <CardMedia
                      className={classes.image}
                      image={"https://firebasestorage.googleapis.com/v0/b/bethany-e504f.appspot.com/o/TopBanner%2Fbg0_1.jpg?alt=media&token=7514981e-4dd9-4f59-b83f-20bc45a42089"}
                      title="daily bread image"
                    />
            
             );
    }
  
}

Index.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Index);
